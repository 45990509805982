<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md6>
                  <v-text-field
                    v-model="emailSearch.keySearch"
                    :label="$t('emailHistory.search')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
                @keyup.enter="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('emailHistory.title')"
          color="green"
          flat
          full-width
        >
          <div @mouseleave="$refs.tblEmailList.expanded=[]">
            <v-data-table
              ref="tblEmailList"
              :no-data-text="$t('common.noDataAvailable')"
              :headers="emailHistoryHeaders"
              :items="emailList"
              :single-select="true"
              :expand="false"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template v-slot:items="props">
                <tr
                  @click="isAdmin() ? props.expanded=!props.expanded : null"
                  @mouseover="isAdmin() ? props.expanded=true : null">
                  <td
                    v-for="(header, index) in emailHistoryHeaders"
                    :key="header.value + index">
                    <span v-if="(header.value == 'toAddress') || (header.value == 'ccAddress') || (header.value == 'bccAddress') || (header.value == 'attached')">
                      <div
                        v-for="(value, index) in props.item[header.value]"
                        :key="value"
                        class="py-1">
                        <span
                          v-show="index < showValue"
                          class="a-state default"
                        >{{ value }}</span>
                      </div>
                    </span>
                    <span
                      v-else-if="header.value == 'sender'"
                      class="a-state primary">{{ props.item[header.value] }}</span>
                    <span
                      v-else-if="header.value == 'subject'"
                      class="cursor-pointer"
                      @click="onViewContent($event, props.item)"
                    >
                      <div style="min-width: 270px">{{ props.item[header.value] }}</div>
                    </span>
                    <span v-else-if="header.value == 'dateCreated'">{{ formatDisplayDate(props.item[header.value]) }}</span>
                    <span
                      v-else-if="header.value == 'description'"
                      class="text-warning">{{ props.item[header.value] }}</span>
                    <div
                      v-else-if="header.value == 'status'"
                      class="text-xs-center">
                      <v-icon
                        v-if="props.item[header.value] == true"
                        class="text-green"
                      >mdi-check</v-icon>
                      <v-icon
                        v-else
                        class="text-red"
                      >mdi-close</v-icon>
                    </div>
                    <span v-else>{{ props.item[header.value] }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="emailPaginate.totalPage"
      :current-page="emailPaginate.currentPage"
      :row-per-page="emailPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <loading-bar :is-loading="isLoading" />
    <emailContentModal
      ref="emailContentModal"/>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
// import stringUtils from 'utils/stringUtils'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import EmailContentModal from './EmailContentModal'
// import ToastType from 'enum/toastType'
import { FOR_CONASI_TARGET } from 'utils/constants'
export default {
  components: {
    EmailContentModal
  },
  data: () => ({
    emailHistoryHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        width: '3%',
        align: 'center'
      },
      {
        sortable: false,
        text: 'emailHistory.sender',
        value: 'sender'
      },
      {
        sortable: false,
        text: 'emailHistory.toAddress',
        value: 'toAddress'
      },
      {
        sortable: false,
        text: 'emailHistory.ccAddress',
        value: 'ccAddress'
      },
      {
        sortable: false,
        text: 'emailHistory.bccAddress',
        value: 'bccAddress'
      },
      {
        sortable: false,
        text: 'emailHistory.subject',
        value: 'subject'
      },
      {
        sortable: false,
        text: 'emailHistory.attached',
        value: 'attached'
      },
      {
        sortable: false,
        text: 'emailHistory.dateCreated',
        value: 'dateCreated'
      },
      {
        sortable: false,
        text: 'emailHistory.status',
        value: 'status',
        align: 'center'
      },
      {
        sortable: false,
        text: 'emailHistory.description',
        value: 'description'
      }
    ],
    emailList: [],
    emailPaginate: {
      totalPage: 1,
      rowPerPage: 1,
      currentPage: 1,
      pageSize: 1
    },
    emailSearch: {
      keySearch: null,
      dateCreate: null
    },
    showValue: 7,
    isLoading: false
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT',
      'GET_EMAIL_HISTORY_LIST_DATA'
    ])
  },
  watch: {
    GET_EMAIL_HISTORY_LIST_DATA () {
      let res = this.GET_EMAIL_HISTORY_LIST_DATA
      let data = res.results
      this.emailPaginate.currentPage = res.page
      this.emailPaginate.totalPage = res.num_pages
      this.emailPaginate.rowPerPage = res.per_page
      this.emailPaginate.pageSize = res.page_size
      this.emailList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let item = {
          stt: i + 1 + (res.page - 1) * res.page_size,
          id: data[i].id,
          toAddress: this.parseAddressInfo(data[i].to),
          ccAddress: this.parseAddressInfo(data[i].cc),
          bccAddress: this.parseAddressInfo(data[i].bcc),
          subject: data[i].subject,
          attached: this.parseAddressInfo(data[i].attach_files),
          sender: data[i].sender,
          dateCreated: dateUtils.formatDate(
            data[i].date_created,
            dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
            dateUtils.STATIC_FORMAT_TIME_DATE
          ),
          status: data[i].status,
          description: data[i].description
        }
        this.emailList.push(item)
      }
    }
  },
  created () {
    this.getEmailHistoryList()
  },
  methods: {
    parseAddressInfo: function (address) {
      if (functionUtils.isEmptyString(address)) {
        return null
      }
      return JSON.parse(address.replace(/'/g, '"'))
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    formatDisplayDate: function (date) {
      return dateUtils.formatBeautyDateTime(date, null)
    },
    onViewContent: function (event, item) {
      event.preventDefault()
      if (functionUtils.isSuperAdmin() || (this.isAdmin() && !FOR_CONASI_TARGET)) {
        this.$refs.emailContentModal.onShowModal(item.id, item.subject)
      }
    },
    onSearch: function () {
      this.getEmailHistoryList()
    },
    getEmailHistoryList: function () {
      let filter = {
        params: {
          page: this.emailPaginate.currentPage,
          keySearch: this.emailSearch.keySearch,
          dateCreate: this.emailSearch.dateCreate

        }
      }
      this.isLoading = true
      this.GET_EMAIL_HISTORY_LIST(filter).then(
        function (res) {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    onPageChange: function (page) {
      this.emailPaginate.currentPage = page
      this.getEmailHistoryList()
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_EMAIL_HISTORY_LIST',
      'GET_EMAIL_HISTORY_CONTENT'
    ])
  }
}
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer !important;
}
</style>
